<template>
    <div class="reports-page">
      <div class="reports-page__select-route not-print">
        <router-link to="/cliente/monitores/cotacoes" class="reports-page__select-route-text">
          Home
        </router-link>
        <span class="material-icons reports-page__route-select-icon">play_arrow</span>
        <div class="reports-page__select-route-text">
          Relatórios
        </div>
      </div>
      <div class="reports-page__page-title">
        Relatórios
      </div>
      <div class="page-section-select-container">
        <div :key="1" :class="{'active': $store.state.tabRelatorioCliente == 1}" @click="selecionarAba(1)" class="page-section-select-size">
          <span class="material-icons-outlined desactive-icon">
            inventory_2
          </span>
          <div class="page-section-select-text">
            Produtos
          </div>
        </div>
        <div :key="2" :class="{'active': $store.state.tabRelatorioCliente == 2}" @click="selecionarAba(2)" class="page-section-select-size">
          <span class="material-icons-outlined desactive-icon">
            category
          </span>
          <div class="page-section-select-text">
            Categorias
          </div>
        </div>
        <div :key="3" :class="{'active': $store.state.tabRelatorioCliente == 3}" @click="selecionarAba(3)" class="page-section-select-size">
          <span class="material-icons-outlined desactive-icon">
            groups
          </span>
          <div class="page-section-select-text">
            Fornecedores
          </div>
        </div>
        <div :key="4" :class="{'active': $store.state.tabRelatorioCliente == 4}" @click="selecionarAba(4)" class="page-section-select-size">
          <span class="material-icons-outlined desactive-icon">
            assignment
          </span>
          <div class="page-section-select-text">
            Requisitantes
          </div>
        </div>
        <div :key="5" :class="{'active': $store.state.tabRelatorioCliente == 5}" @click="selecionarAba(5)" class="page-section-select-size">
          <span class="material-icons-outlined desactive-icon">
            query_stats
          </span>
          <div class="page-section-select-text">
            Análise de Preço
          </div>
        </div>
      </div>
      <!-- <div class="tabs not-print">
        <ul class="nav nav-tabs" role="tablist">
          <li :key="1" :class="{'active': $store.state.tabRelatorioCliente == 1}" @click="selecionarAba(1)">
            <a  class="nav-link">Produtos</a>
          </li>
          <li :key="2" :class="{'active': $store.state.tabRelatorioCliente == 2}" @click="selecionarAba(2)">
            <a class="nav-link">Categorias</a>
          </li>
          <li :key="3" :class="{'active': $store.state.tabRelatorioCliente == 3}" @click="selecionarAba(3)">
            <a class="nav-link">Fornecedores</a>
          </li>
          <li :key="4" :class="{'active': $store.state.tabRelatorioCliente == 4}" @click="selecionarAba(4)">
            <a class="nav-link">Requisitantes</a>
          </li>
          <li :key="5" :class="{'active': $store.state.tabRelatorioCliente == 5}" @click="selecionarAba(5)">
            <a class="nav-link">Análise de Preço</a>
          </li>
        </ul>
      </div> -->
      <TabProduto v-if="$store.state.tabRelatorioCliente == 1" />
      <TabCategoria v-if="$store.state.tabRelatorioCliente == 2" />
      <TabFornecedores v-if="$store.state.tabRelatorioCliente == 3" />
      <TabRequisitantes v-if="$store.state.tabRelatorioCliente == 4" />
      <TabAnalisePreco v-if="$store.state.tabRelatorioCliente == 5" :cotacaoInput="request_id" />
      <TabAnaliseData v-if="$store.state.tabRelatorioCliente == 6" />
    </div>
      <!-- <div class="monitors">
          <div class="body">
              <div class="container-fluid">
                  <div class="contents">
                      <div class="tabs">
                          <ul class="nav nav-tabs" role="tablist">
                              <li :key="1" class="nav-item" :class="{'active': $store.state.tabRelatorioCliente == 1}" @click="selecionarAba(1)">
                              <a  class="nav-link">Produtos</a>
                              </li>
                              <li :key="2"  class="nav-item" :class="{'active': $store.state.tabRelatorioCliente == 2}" @click="selecionarAba(2)">
                              <a class="nav-link">Categorias</a>
                              </li>
                              <li :key="3"  class="nav-item" :class="{'active': $store.state.tabRelatorioCliente == 3}" @click="selecionarAba(3)">
                              <a class="nav-link">Fornecedores</a>
                              </li>
                              <li :key="4"  class="nav-item" :class="{'active': $store.state.tabRelatorioCliente == 4}" @click="selecionarAba(4)">
                              <a class="nav-link">Requisitantes</a>
                              </li>
                              <li :key="5"  class="nav-item" :class="{'active': $store.state.tabRelatorioCliente == 5}" @click="selecionarAba(5)">
                              <a class="nav-link">Análise de Preço</a>
                              </li> -->
                              <!-- <li :key="6"  class="nav-item" :class="{'active': $store.state.tabRelatorioCliente == 6}" @click="selecionarAba(6)">
                              <a class="nav-link">Análise por Data</a>
                              </li> -->
      <!--                     </ul>
                      </div>
                  </div>
              </div>
  
              <TabProduto v-if="$store.state.tabRelatorioCliente == 1" />
              <TabCategoria v-if="$store.state.tabRelatorioCliente == 2" />
              <TabFornecedores v-if="$store.state.tabRelatorioCliente == 3" />
              <TabRequisitantes v-if="$store.state.tabRelatorioCliente == 4" />
              <TabAnalisePreco v-if="$store.state.tabRelatorioCliente == 5" :cotacaoInput="request_id" />
              <TabAnaliseData v-if="$store.state.tabRelatorioCliente == 6" />
          </div>
      </div> -->
  </template>
  
  <script>
  import * as CONSTANTS from "@/constants/constants";
  import Pagination from "@/components/cliente/base-components/Pagination";
  import TabProduto from "./tabs/_products-analysis";
  import TabCategoria from "./tabs/_category-analysis";
  import TabFornecedores from "./tabs/_sellers-analysis.tab";
  import TabRequisitantes from "./tabs/_clients-analysis";
  import TabAnalisePreco from "./tabs/_price-analysis.tab";
  import TabAnaliseData from "./tabs/_date-analysis.tab";
  
  export default {
      props: {
          request_id: {
              type: Number
          },
          tab: {
              type: String
          }
      },
      components: {
          TabProduto,
          TabCategoria,
          TabFornecedores,
          TabRequisitantes,
          TabAnalisePreco,
          TabAnaliseData,
          ccPagination: Pagination
      },
      data() {
          return {
              page_error: false,
              CONSTANTS: CONSTANTS,
              breadcrumbs : [
                  {
                  name: 'Relatórios'
                  }
              ],
  
          };
      },
      computed: {},
      methods: {
          selecionarAba(aba) {
              this.$store.commit("changeTabRelatorioCliente", aba);
          },
          getFiltroCategoria() {
          var requestUrl =
              `${CONSTANTS.API_URL}/listarcategoriascliente?cliente_id=` +
              this.$store.state.current_user.cli_id;
  
          axios
              .get(requestUrl, {
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              }
              })
              .then(({ data }) => {
              this.$store.commit("setCategoriaFiltroRelatorio", data.categorias);
              this.getFiltroFornecedores();
              // this.produtos = data.produtos.data;
              })
              .catch(error => {
              this.loading = false;
              if (error.response.status === 401) {
                  if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                  location.href = CONSTANTS.ROUTE_LOGIN;
                  }
              } else if (error.response.status === 500) {
                  if (error.response.data.mensagem) {
                  this.mensagemErro = error.response.data.mensagem;
                  } else {
                  this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                  }
              }
              });
          },
          getFiltroFornecedores() {
          var requestUrl = `${CONSTANTS.API_URL}/getfornecedorescliente`;
  
          axios
              .get(requestUrl, {
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              }
              })
              .then(({ data }) => {
              this.$store.commit(
                  "setFornecedoresFiltroRelatorio",
                  data.fornecedores
              );
              this.getFiltroRequisitantes();
              // this.produtos = data.produtos.data;
              })
              .catch(error => {
              this.loading = false;
              if (error.response.status === 401) {
                  if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                  location.href = CONSTANTS.ROUTE_LOGIN;
                  }
              } else if (error.response.status === 500) {
                  if (error.response.data.mensagem) {
                  this.mensagemErro = error.response.data.mensagem;
                  } else {
                  this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                  }
              }
              });
          },
          getFiltroRequisitantes() {
          var requestUrl = `${CONSTANTS.API_URL}/getrequisitantescliente`;
  
          axios
              .get(requestUrl, {
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              }
              })
              .then(({ data }) => {
              this.$store.commit(
                  "setRequisitantesFiltroRelatorio",
                  data.requisitantes
              );
              // this.produtos = data.produtos.data;
              })
              .catch(error => {
              this.loading = false;
              if (error.response.status === 401) {
                  if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                  location.href = CONSTANTS.ROUTE_LOGIN;
                  }
              } else if (error.response.status === 500) {
                  if (error.response.data.mensagem) {
                  this.mensagemErro = error.response.data.mensagem;
                  } else {
                  this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                  }
              }
              });
          }
      },
      mounted() {
          this.getFiltroCategoria();
          if(this.tab == "analise"){
              this.selecionarAba(5);
          }
  
      }
  };
  </script>
  
  <style lang="scss" scoped>
  @import './reports.page';
  .reports-page {
    padding: 0 40px 70px 0;
    font-size: 1vw;
  }
  .reports-page__select-route{
    display: flex;
    align-items: center;
  }
  .reports-page__select-route-text{
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
  }
  .reports-page__route-select-icon{
    color:  var(--primary-color);
    margin-right: 20px;
  }
  .reports-page__page-title{
    font-weight: 600;
    font-size: 2.72em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
  }
  .active a{
    color: var(--primary-color);
    border: 1px solid #e5e5e5;
  }
  .nav-link {
    color: #979797;
    font-weight: 400;
    letter-spacing: 0.15px;
    padding: 0.8em 1.8em;
    cursor: pointer;
  }
  .nav-link:hover {
    color: var(--primary-color);
    transition: ease 0.3s all;
  }
  .page-section-select-container {
    border-bottom: 0.5px solid #E5E5E5;
    display: flex;
    margin-top: 40px;
    align-items: flex-end;
    padding-bottom: 1vh;
  }
  .page-section-select-size {
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    margin-right: 2vw;
  }
  .page-section-select-size:hover {
    background-color: #fdf0e9;
  }
  .page-section-select-size-active {
    cursor: default;
    border-radius: 8px 8px 8px 8px;
    margin-top: 20px;
    background-color: #fdf0e9;
  }
  .page-section-select-text {
    font-weight: 600;
    font-size: 1.1em;
    color: #202020;
    margin-left: 1vw;
  }
  .page-section-select-size:hover > .page-section-select-text{
    color: var(--primary-color);
  }
  .active-icon {
    color: var(--primary-color) !important;
  }
  .desactive-icon {
    color: var(--primary-color);
  }
  .active div {
    color: var(--primary-color);
  }
  .active {
    background-color: #fdf0e9;
  }
  @media only screen and (max-width: 960px) {
    .page-section-select-text {
      font-size: 2em;
    }
  }
  
  </style>
