<template>
  <div class="box cc-box cc-box-body">

    <div class="box-body ">
      <div>
        <div class="row only-print">
          <div style="margin-top: 8px; width: 180px; float: left; margin-bottom: 15px;">
            <img src="images/clogo.png" />
          </div>
          <div
            style="margin-top: 0px;margin-bottom: 5px;text-transform: uppercase;width: 50%;float: left;text-align: center;"
          >
            <h4>Produtos</h4>
          </div>
        </div>
        <div class="row only-print">
          <div style="margin-top: 0px;margin-bottom: 5px;text-transform: uppercase;float: left;">
            <h5>{{$store.state.current_user.cliente ? $store.state.current_user.cliente.cli_nome : "" }}</h5>
            <h5>CNPJ: {{$store.state.current_user.cliente ? $store.state.current_user.cliente.cli_cnpj : "" | cnpj }}</h5>
          </div>
        </div>
        <div class="row only-print">
          <div style="width: 120px;float: left;">
            <p style="padding: 0px;margin: 0px">Nome Produto:</p>
            <span>{{filtroProduto ? filtroProduto : "Todos"}}</span>
          </div>
          <div style="width: 120px;float: left;">
            <p style="padding: 0px;margin: 0px">Requisitante:</p>
            <span>{{getNomeRequisitante ? getNomeRequisitante : "Todos"}}</span>
          </div>
          <div style="width: 120px;float: left;">
            <p style="padding: 0px;margin: 0px">Fornecedor:</p>
            <span>{{getNomeFornecedor ? getNomeFornecedor : "Todos"}}</span>
          </div>
          <div style="width: 120px;float: left;">
            <p style="padding: 0px;margin: 0px">Categoria:</p>
            <span>{{getNomeCategoria ? getNomeCategoria : "Todos"}}</span>
          </div>
          <div style="width: 90px;float: left;" v-if="fDateIni">
            <p style="padding: 0px;margin: 0px">Data Inicial:</p>
            <span>{{fDateIni}}</span>
          </div>
          <div style="width: 90px;float: left;" v-if="fDateFim">
            <p style="padding: 0px;margin: 0px">Data Final:</p>
            <span>{{fDateFim}}</span>
          </div>
        </div>
        <div class="row" style="padding: 10px;">
         
          
          <!-- <div class="not-print col-sm-2 col-md-2 col-lg-2">
          </div>-->

          <div class="col-md-6 col-lg-6 multiselect-custom d-flex justify-content-center ">
            <label class="inputLarg3" style="padding-top:5.4px!important">
              Filtros
              <select
                v-model="filtroRequisitante"
                class="inputProdutoNome inputLarg2"
                id="exampleFormControlSelect1"
              >
                <option :key="-1" :value="-1" disabled selected hidden>Requisitantes</option>

                <option :key="1" :value="-1">Todos os Requisitantes</option>
                <option
                  v-for="requisitante in $store.state.requisitantesFiltro"
                  :key="requisitante.loj_id"
                  :value="requisitante.loj_id"
                >{{ requisitante.loj_descricao }}</option>
              </select>
            </label>

            <select
              v-model="filtroFornecedor"
              class="inputProdutoNome inputLarg2 paddingFiltros"
              id="exampleFormControlSelect1" style="margin-top:39px !important"
            >
              <option :key="-1" :value="-1" disabled selected hidden>Fornecedores</option>

              <option :key="1" :value="-1">Todos os Fornecedores</option>
              <option
                v-for="fornecedor in $store.state.fornecedorFiltro"
                :key="fornecedor.for_id"
                :value="fornecedor.for_id"
              >{{ fornecedor.for_nome }}</option>
            </select>

            <select
              v-model="filtroCategoria"
              class="inputProdutoNome inputLarg2 paddingFiltros"
              id="exampleFormControlSelect1" style="margin-top:39px !important"
            >
              <option :key="-1" :value="-1" disabled selected hidden>Categoria</option>
              <option :key="0" :value="-1">Todas</option>
              <option
                v-for="categoria in $store.state.categoriasFiltro"
                :key="categoria.cat_id"
                :value="categoria.cat_id"
              >{{ categoria.cat_descricao }}</option>
            </select>
            
          </div>
          <div class="col-md-2 col-lg-2 multiselect-custom">
            <label  style="margin-right: 25px;padding-top:1px!important; margin-top:7px !important">
               Data de Início
            </label>
             <date-picker
             :style="{ backgroundImage: `url('${image2}')` }"
                v-model="$store.state.dateIniRelatorio"
                :config="date_picker_options"
                class="dataPickerEntrega"
              ></date-picker>
          </div>
          <div class="col-md-2 col-lg-2 multiselect-custom">
            <!-- <date-range-picker id="dfdfd" class="dataPickerEntrega" datesub="0" :dateAutal="$store.state.dateIniRelatorio" v-on:daterangechanged="onDateRangeChangedIni">
            </date-range-picker>-->
            <label style="padding-top:1px!important; margin-top:7px !important">
              Data Fim
            </label>
              <date-picker
              :style="{ backgroundImage: `url('${image2}')` }"
                v-model="$store.state.dateFimRelatorio"
                :config="date_picker_options"
                class="dataPickerEntrega"
              ></date-picker>
              <!-- <date-range-picker
                id="dfdfd2"
                class="dataPickerEntrega"
                datesub="0"
                :dateAutal="$store.state.dateFimRelatorio"
                v-on:daterangechanged="onDateRangeChangedFim"
              ></date-range-picker>-->
          </div>
            <button style="margin-top: 36px !important" @click="getProdutos(1, false)" class="botaoImprimir">
              <i class="fa fa-search fa-lg imprimirIcone" aria-hidden="true"></i>
            </button>
           <button style="margin-top: 36px !important" class="botaoImprimir"><i data-v-a8ed716c="" class="fa fa-print"></i> Imprimir</button>
         
        </div>
        <div class="row not-print" style="padding: 10px;">
          
          <div class="not-print col-sm-12 col-md-3 col-lg-3">
            <label class="inputLarg1">
              Agrupar Por:
             <select v-model="filtroGroupBy" class="inputProdutoNome inputLarg2" id="exampleFormControlSelect1">
              <option :key="0" value="p.pro_id">Produto</option>
              <option :key="1" value="f.for_id">Fornecedor</option>
              <option :key="2" value="cat.cat_id">Categoria</option>
              <option :key="3" value="l.loj_id">Requisitantes</option>
            </select>
            </label>
          </div>
          <div class="col-md-3  col-lg-3 p-3" style="padding-left: 6% !important;padding-top: 19px !important">
              <button class="botaoFiltro" >Limpar Filtros</button>
            </div>
          <div class="col-md-3 col-lg-3 offset-md-3 offset-lg-3 pt-4" style="padding-left:10% !important">
            <div class="totalDiv">
              <b class="total">Total: R$ {{formartNumber(totalPedido)}}</b>
            </div>
          </div>
          
         
          <!--         
          <button style="font-size: 17px;padding: 2px 10px !important" type="button" class="btn btn-warning btn-sm" @click="getProdutos(1, false)">
            <i class="fa fa-search"></i>
          </button>
          <button type="button" class="btn btn-warning btn-sm " style="font-size: 17px;padding: 2px 10px !important" @click="getProdutos(1,false, true)">
            <i class="fa fa-print fa-fw"></i>
          </button>-->
        </div>
      </div>
<div class="table-responsive">
  <div class="fold-table">
    <div class="table ">

      <div class="table-row" v-for="(produto, index) in produtos" :key="produto.mes" :ref="'div-' + produto.mes">
        <div class="row-table view" :ref="'view-' + produto.mes" @click="clickTable(produto.mes, index)">
          <div class="cell-table cell-table-row">{{meses[produto.mes - 1].label}}</div>
          <div class="cell-table width115 cell-table-row">{{formartNumber(produto.valorTotal)}}</div>
        </div>
        <div class="sec-table2 fold2" :ref="'fold-' + produto.mes" v-if="filtroGroupBy == 'p.pro_id'">
          <div class="row-table2 header-table">
            <div class="cell-table2 ">Descricao</div>
            <div class="cell-table2 width115">Quantidade</div>
            <div class="cell-table2 width115">Valor Total</div>
            <div class="cell-table2 width115">Valor Unit.</div>
            <div class="cell-table2 width115 ">%</div>
            <div class="cell-table2 width130 ">% Acumulado</div>
          </div>
          <div style="text-align: center;font-size: 26px;color: #f39c12;" v-if="!produto.produtos">
            <i class="fa fa-circle-o-notch fa-spin" style="color: var(--primary-color)"></i>
          </div>
          <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index" >
            <div class="cell-table2 ">{{produtoInt.pro_descricao}}</div>
            <div class="cell-table2 width115">{{produtoInt.quantidade}}</div>
            <div class="cell-table2 width115">{{formartNumber(produtoInt.valorTotal)}}</div>
            <div class="cell-table2 width115">{{produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorTotal / produtoInt.quantidade ) : formartNumber(produtoInt.valorTotal / (produtoInt.quantidade ))}}</div>
            <div class="cell-table2 width115">{{produtoInt.percent}}%</div>
            <div class="cell-table2 width130">{{produtoInt.percentAcumulate}}%</div>
          </div>
        </div>
        <div class="sec-table2 fold2" :ref="'fold-' + produto.mes" v-if="filtroGroupBy == 'f.for_id'">
          <div class="row-table2 header-table">
            <div class="cell-table">Fornecedor</div>
            <div class="cell-table width115">
              <span class="click-pointer" @click="setFiltroOrder('quantidade', 'desc')">Quantidade
                <i v-if="ordernar=='quantidade'" class="fa" :class="classOrder"></i>
              </span>
            </div>
            <div class="cell-table width115">
              <span class="click-pointer" @click="setFiltroOrder('valorTotal', 'desc')">Valor Total
                <i v-if="ordernar=='valorTotal'" class="fa" :class="classOrder"></i>
              </span>
            </div>
            <div class="cell-table width115">%</div>
          </div>
          <div style="text-align: center;font-size: 26px;color: #f39c12;" v-if="!produto.produtos">
            <i class="fa fa-circle-o-notch fa-spin" style="color: var(--primary-color)"></i>
          </div>
          <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
            <div class="cell-table">{{produtoInt.nomeFornecedor}}</div>
            <div class="cell-table width115">{{produtoInt.quantidade}}</div>
            <div class="cell-table width115">{{formartNumber(produtoInt.valorTotal)}}</div>
            <div class="cell-table width115">{{getPercentual(produtoInt)}}</div>
          </div>
        </div>
        <div class="sec-table2 fold2" :ref="'fold-' + produto.mes" v-if="filtroGroupBy == 'cat.cat_id'">
          <div class="row-table2 header-table">
            <div class="cell-table">Categoria</div>
            <div class="cell-table width115">
              <span class="click-pointer" @click="setFiltroOrder('quantidade', 'desc')">Quantidade
                <i v-if="ordernar=='quantidade'" class="fa" :class="classOrder"></i>
              </span>
            </div>
            <div class="cell-table width115">
              <span class="click-pointer" @click="setFiltroOrder('valorTotal', 'desc')">Valor Total
                <i v-if="ordernar=='valorTotal'" class="fa" :class="classOrder"></i>
              </span>
            </div>
            <div class="cell-table width115">%</div>
          </div>
          <div style="text-align: center;font-size: 26px;color: #f39c12;" v-if="!produto.produtos">
            <i class="fa fa-circle-o-notch fa-spin" style="color: var(--primary-color)"></i>
          </div>
          <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
            <div class="cell-table">{{produtoInt.cat_descricao}}</div>
            <div class="cell-table width115">{{produtoInt.quantidade}}</div>
            <div class="cell-table width115">{{formartNumber(produtoInt.valorTotal)}}</div>
            <div class="cell-table width115">{{getPercentual(produtoInt)}}</div>
          </div>
        </div>
        <div class="sec-table2 fold2" :ref="'fold-' + produto.mes" v-if="filtroGroupBy == 'l.loj_id'">
          <div class="row-table2 header-table">
            <div class="cell-table">Loja</div>
            <div class="cell-table width115">
              <span class="click-pointer" @click="setFiltroOrder('quantidade', 'desc')">Quantidade
                <i v-if="ordernar=='quantidade'" class="fa" :class="classOrder"></i>
              </span>
            </div>
            <div class="cell-table width115">
              <span class="click-pointer" @click="setFiltroOrder('valorTotal', 'desc')">Valor Total
                <i v-if="ordernar=='valorTotal'" class="fa" :class="classOrder"></i>
              </span>
            </div>
            <div class="cell-table width115">%</div>
          </div>
          <div style="text-align: center;font-size: 26px;color: #f39c12;" v-if="!produto.produtos">
            <i class="fa fa-circle-o-notch fa-spin" style="color: var(--primary-color)"></i>
          </div>
          <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
            <div class="cell-table">{{produtoInt.loj_descricao}}</div>
            <div class="cell-table width115">{{produtoInt.quantidade}}</div>
            <div class="cell-table width115">{{formartNumber(produtoInt.valorTotal)}}</div>
            <div class="cell-table width115">{{getPercentual(produtoInt)}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
</div>

<div class="box-footer clearfix not-print">
  <cc-paginacao :paginaAtual="paginaAtual" :totalPaginas="totalPaginas" :funcao="getProdutos"></cc-paginacao>
</div>

<div class="overlay not-print" v-if="loading">
  <i class="fa fa-circle-o-notch fa-spin" style="color: var(--primary-color)"></i>
</div>
</div>
</template>


<script>
import { filtroData } from "@/mixins/filtroData";
import { Money } from "v-money";
import { mascaraCNPJ } from "@/mixins/mascaraCNPJ";
import numeral from "numeral";
import Paginacao from "@/components/shared/Paginacao";
import Multiselect from "vue-multiselect";

import * as CONSTANTS from "@/constants/constants";
// switch between locales
// numeral.locale("pt");
export default {
  components: {
    ccPaginacao: Paginacao,
    Multiselect
  },
  mixins: [filtroData, mascaraCNPJ],
  watch: {
    filtroGroupBy: function(val) {
      // this.showProdutos = false;
      setTimeout(() => {
        this.meses.map((item, index)=>{
          if(this.$refs["view-" + item.value]){
            this.produtos[index].produtos = null;
            this.$refs["view-" + item.value][0].classList.remove("open");
            this.$refs["fold-" + item.value][0].classList.remove("open");

          }
        })
      }, 100);
    }
  },
  data() {
    return {
      image2:"/images/calendar.png",
      paginaAtual: 1,
      totalPaginas: 1,
      itensPorPagina: 0,
      totalPedido: 0,
      totalItens: 0,
      date_picker_options: {
        format: "DD/MM/YYYY",
        locale: "pt-br",
        useCurrent: false
      },
      produtos: [],
      filtroProduto: "",
      filtroGroupBy: "p.pro_id",
      filtroRequisitante: -1,
      filtroFornecedor: -1,
      filtroCategoria: -1,
      filtroMes: 1,
      filtroMesFinal: 12,
      meses: [
        { label: "Janeiro", value: 1 },
        { label: "Fevereiro", value: 2 },
        { label: "Março", value: 3 },
        { label: "Abril", value: 4 },
        { label: "Maio", value: 5 },
        { label: "Junho", value: 6 },
        { label: "Julho", value: 7 },
        { label: "Agosto", value: 8 },
        { label: "Setembro", value: 9 },
        { label: "Outubro", value: 10 },
        { label: "Novembro", value: 11 },
        { label: "Dezembro", value: 12 }
      ],
      classOrder: "fa-sort-asc",
      ordernar: "valorTotal",
      tipoOrder: "desc",
      loading: false,
      fDateIni: moment().format("YYYY-MM-DD"),
      fDateFim: moment().format("YYYY-MM-DD")
    };
  },
  computed: {
    getNomeCategoria() {
      let lojaSelect = this.$store.state.categoriasFiltro.filter(
        item => item.cat_id == this.filtroCategoria
      );
      if (lojaSelect.length == 0) {
        return "";
      }
      return lojaSelect[0].cat_descricao;
    },
    getNomeRequisitante() {
      let requisitanteSelect = this.$store.state.requisitantesFiltro.filter(
        item => item.loj_id == this.filtroRequisitante
      );
      
      if (requisitanteSelect.length == 0) {
        return "";
      }
      return requisitanteSelect[0].loj_descricao;
    },
    getNomeFornecedor() {
      let fornecedorSelect = this.$store.state.fornecedorFiltro.filter(
        item => item.for_id == this.filtroFornecedor
      );
      if (fornecedorSelect.length == 0) {
        return "";
      }
      return fornecedorSelect[0].for_nome;
    }
  },
  methods: {
    setFiltroOrder(order, tipo) {
      if (order == this.ordernar && tipo == this.tipoOrder && tipo == "asc") {
        this.tipoOrder = "desc";
      } else if (
        order == this.ordernar &&
        tipo == this.tipoOrder &&
        tipo == "desc"
      ) {
        this.tipoOrder = "asc";
      } else {
        this.tipoOrder = tipo;
      }
      this.classOrder = "fa-sort-" + this.tipoOrder;
      
      this.ordernar = order;

      if (this.produtos.length > 0) {
        this.getProdutos(this.paginaAtual);
      }
    },
    formartNumber(n) {
      if (n) {
        return numeral(n.toString().replace(".", ",")).format("0,0.00");
      } else {
        return n;
      }
    },
    getPercentual(produto) {
      return (
        parseFloat((produto.valorTotal * 100) / this.totalPedido).toFixed(2) +
        "%"
      );
    },
    onDateRangeChangedIni: function(picker) {
      
      this.fDateIni = picker.startDate.format("YYYY-MM-DD");
      this.$store.commit(
        "changeDateIniRelatorioCliente",
        picker.startDate.format("YYYY-MM-DD")
      );
    },
    clickTable(index, indexProduto) {
      this.produtos[indexProduto].produtos = null;
      if (
        this.$refs["view-" + index][0].classList.length == 2 &&
        !this.produtos[indexProduto].produtos
      ) {
        // this.$refs["div-" + index][0].style.background = "#f1f1f1";

        this.getProdutosInternos(index, indexProduto);
      } else {
        // this.$refs["div-" + index][0].style.background = "#FFF";
      }
      this.$refs["view-" + index][0].classList.toggle("open");
      this.$refs["fold-" + index][0].classList.toggle("open");
    },
    getProdutosInternos(id, index) {
      var begin = moment(moment().format("YYYY-" + id + "-01")).format(
        "YYYY-MM-01"
      );
      var end =
        moment().format("YYYY-" + id + "-") +
        moment(moment().format("YYYY-" + id + "-01")).daysInMonth();
      this.produtos[index].loading = true;
      var requestUrl =
        `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?imprimir=true&ordenar=valorTotal&tipoorder=desc`;
      requestUrl += "&dateIni=" + begin + "&dateFim=" + end;
      if (this.filtroGroupBy != "") {
        requestUrl += "&groupBy=" + this.filtroGroupBy;
      }
      if (this.filtroProduto != "") {
        requestUrl += "&nomeproduto=" + this.filtroProduto;
      }
      if (this.filtroRequisitante != -1) {
        requestUrl += "&requisitante=" + this.filtroRequisitante;
      }
      if (this.filtroFornecedor != -1) {
        requestUrl += "&fornecedor=" + this.filtroFornecedor;
      }
      if (this.filtroCategoria != -1) {
        requestUrl += "&categoria=" + this.filtroCategoria;
      }
      // if (this.filtroCategoria != -1) {
      // }

      axios
        .get(requestUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        })
        .then(({ data }) => {
          this.produtos[index].loading = false;
          let lastPercent = 0;
          this.produtos[index].produtos = data.produtos.map(itemInt => {
            let newItemInt = itemInt;
            newItemInt.percent = parseFloat(
              (itemInt.valorTotal * 100) / this.produtos[index].valorTotal
            ).toFixed(2);
            newItemInt.percentAcumulate = parseFloat(
              parseFloat(newItemInt.percent) + parseFloat(lastPercent)
            ).toFixed(2);
            lastPercent = newItemInt.percentAcumulate;
            return newItemInt;
          });
          
          this.$forceUpdate();
          // setando produtos
        })
        .catch(error => {
          
          this.produtos[index].loading = false;
          if (error.response.status === 401) {
            if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
              location.href = CONSTANTS.ROUTE_LOGIN;
            }
          } else if (error.response.status === 500) {
            if (error.response.data.mensagem) {
              this.mensagemErro = error.response.data.mensagem;
            } else {
              this.mensagemErro = "Não foi possível abrir os dados gerenciais";
            }
          }
        });
    },
    onDateRangeChangedFim: function(picker) {
      
      this.fDateFim = picker.startDate.format("YYYY-MM-DD");
      this.$store.commit(
        "changeDateFimRelatorioCliente",
        picker.startDate.format("YYYY-MM-DD")
      );
    },
    getPercentualNumber(produto) {
      return parseFloat((produto.valorTotal * 100) / this.totalPedido);
    },
    getProdutos(pagina, variable, imprimir = false) {
      var begin = moment(
        moment().format("YYYY-" + this.filtroMes + "-01")
      ).format("YYYY-MM-01");
      var end =
        moment().format("YYYY-" + this.filtroMesFinal + "-") +
        moment(
          moment().format("YYYY-" + this.filtroMesFinal + "-01")
        ).daysInMonth();
      this.loading = true;
      var requestUrl =
        `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?groupBy=Month(pe.data_geracao)&ordenar=mes&tipoorder=asc&page=` +
        pagina;

      //   if (this.fDateIni && this.fDateFim) {
      requestUrl += "&dateIni=" + begin + "&dateFim=" + end;
      requestUrl += "&mesesIni=" + this.filtroMes;
      requestUrl += "&mesesFim=" + this.filtroMesFinal;
      //   }
      if (this.filtroProduto != "") {
        requestUrl += "&nomeproduto=" + this.filtroProduto;
      }
      if (this.filtroRequisitante != -1) {
        requestUrl += "&requisitante=" + this.filtroRequisitante;
      }
      if (this.filtroFornecedor != -1) {
        requestUrl += "&fornecedor=" + this.filtroFornecedor;
      }
      if (this.filtroCategoria != -1) {
        requestUrl += "&categoria=" + this.filtroCategoria;
      }
      if (imprimir) {
        requestUrl += "&imprimir=" + true;
        // var telaImpressao = window.open("about:blank");
      }
      axios
        .get(requestUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        })
        .then(({ data }) => {
          this.loading = false;

          if (imprimir) {
            requestUrl += "&imprimir=" + true;
            // var telaImpressao = window.open("about:blank");
            this.totalPedido = data.totalProdutos.valorTotal
              ? data.totalProdutos.valorTotal
              : 0;
            this.produtos = data.produtos.map((item, index) => {
              let newItem = item;
              let lastPercent = 0;
              newItem.percent = this.getPercentualNumber(item).toFixed(2);
              //percentual acumulado para itens internos
              if (item.produtos) {
                newItem.produtos = item.produtos.map(itemInt => {
                  let newItemInt = itemInt;
                  newItemInt.percent = parseFloat(
                    (itemInt.valorTotal * 100) / item.valorTotal
                  ).toFixed(2);
                  newItemInt.percentAcumulate = parseFloat(
                    parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                  ).toFixed(2);
                  lastPercent = newItemInt.percentAcumulate;
                  return newItemInt;
                });
              }

              return newItem;
            });
            setTimeout(() => {
              window.print();
              this.imprimindo = false;
            }, 100);
          } else {
            this.totalPedido = data.totalProdutos.valorTotal
              ? data.totalProdutos.valorTotal
              : 0;
            this.paginaAtual = data.produtos.current_page;
            this.totalPaginas = data.produtos.last_page;
            this.itensPorPagina = data.produtos.per_page;
            this.totalItens = data.produtos.total;
            this.produtos = data.produtos.data.map((item, index) => {
              let newItem = item;
              let lastPercent = 0;
              newItem.percent = this.getPercentualNumber(item).toFixed(2);
              //percentual acumulado para itens internos
              if (item.produtos) {
                newItem.produtos = item.produtos.map(itemInt => {
                  let newItemInt = itemInt;
                  newItemInt.percent = parseFloat(
                    (itemInt.valorTotal * 100) / item.valorTotal
                  ).toFixed(2);
                  newItemInt.percentAcumulate = parseFloat(
                    parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                  ).toFixed(2);
                  lastPercent = newItemInt.percentAcumulate;
                  return newItemInt;
                });
              }

              return newItem;
            });
          }
        })
        .catch(error => {
          
          this.loading = false;
          if (error.response.status === 401) {
            if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
              location.href = CONSTANTS.ROUTE_LOGIN;
            }
          } else if (error.response.status === 500) {
            if (error.response.data.mensagem) {
              this.mensagemErro = error.response.data.mensagem;
            } else {
              this.mensagemErro = "Não foi possível abrir os dados gerenciais";
            }
          }
        });
    }
  },
  mounted() {}
};
</script>
<style>
.multiselect__tags {
  max-height: 30px;
  overflow-y: scroll;
}
</style>
