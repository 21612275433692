import moment from "moment";

export const filtroData = {
  filters: {
    moment(date) {
      moment.locale("pt-br");
      return moment(date).format("L") == "Invalid date"
        ? ""
        : moment(date).format("DD/MM/YYYY HH:mm");
    }
  }
};
