export const mascaraCNPJ = {
  filters: {
    cnpj(valor) {
      if (valor) {
        if (valor.length == 13) {
          valor = "0" + valor.replace(/\D/g, "");
        }
        if (valor.length == 13) {
          valor = "0" + valor.replace(/\D/g, "");
        }

        return (valor + "").replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        );
      } else {
        return valor;
      }
    }
  },
  methods: {
    cnpj(valor) {
      if (valor) {
        return (valor + "").replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          "$1.$2.$3/$4-$5"
        );
      } else {
        return valor;
      }
    }
  }
};
